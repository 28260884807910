import React from "react";
import crossmargin from "../../assets/images/crossmargin.png";
import hedgepoloniex from "../../assets/images/hedgepoloniex.png";
import radeallassets from "../../assets/images/radeallassets.png";
import automatically from "../../assets/images/automatically.png";
import cardtocrypto from "../../assets/images/cardtocrypto.png";
import trustedsecure from "../../assets/images/trustedsecure.png";
import trade from "../../assets/images/trade.png";
import support227 from "../../assets/images/support227.png";
import ethereum5051 from "../../assets/images/ethereum5051.png";
import ethereum5052 from "../../assets/images/ethereum5052.png";
import ethereum5050 from "../../assets/images/ethereum5050.png";
import ethereum5053 from "../../assets/images/ethereum5053.png";
import cryptocurrency from "../../assets/images/cryptocurrency.png";
import currencyCategory71949 from "../../assets/images/currencyCategory71949.png";
import solanalogo from "../../assets/images/solanalogo.png";
import currencyCategory6bd4a from "../../assets/images/currencyCategory6bd4a.png";
import FAQ from "../FAQ/FAQ";
import Ready_to_Exchange from "../Ready_to_Exchange/Ready_to_Exchange";
import { Link } from "react-router-dom";
import defi from "../../assets/images/defi.png";
import crypto from "../../assets/images/crypto.png";
const EarnCrypto = () => {
  return (
    <div>
      <>
        <section id="Defi" className="earn_crypto pt-120 pb-120">
          <div className="container">
            <div className="row gy-5 gy-md-6">
              {/* <div className="col-12">
                <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 wow fadeInUp">
                  <h2>
                  Vision &{" "}
                    <span className="p1-color"> Mission</span>
                  </h2>
                </div>
              </div> */}
              <div className="col-xl-6">
                <div className="earn_crypto__cardone rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                  <div className="d-flex align-items-start justify-content-between flex-wrap gap-3 mb-8 mb-sm-10 mb-md-15">
                    <div>
                      <h2 className="mb-2 p1-color">
                      Vision & Mission
                      </h2>
                    </div>
                    <p>
                    At Reusedcoin, our vision extends beyond mere transactions; we aim to redefine the future of the reuse market. With a commitment to sustainability and innovation, we empower users to participate in a new era of real estate investment. Our mission is to create a global ecosystem where Reusedcoin serves as a catalyst for positive change, rewarding users and fostering a community dedicated to environmental responsibility and financial empowerment. Join us in pioneering the future of reuse and real estate investment - together, we can soar to new heights and achieve revolutionary success!
                    
                    </p>
                   
                  </div>
                </div>
              </div>
              <div className="col-xl-6">

              <img
            className="imgs"
            src={defi}
            alt="light"
          />

              </div>
              {/* <div className="col-xl-12">
              <h2 className="mb-2 p1-color text-center">
                    How Does DeFi Work?
                      </h2>
                      <p>DeFi provides financial services using cryptocurrencies and smart contracts, eliminating the need for intermediaries such as guarantors. By utilizing DeFi technology, people can execute lending (in which users can lend out their cryptocurrencies and receive interest instantly); receiving loan; making p2p lending without a broker; saving cryptocurrency and earning higher interest rate than a bank, and buying derivatives.

Decentralized apps (dApps) allow two or more parties to do the transactions directly using blockchain technology and smart contracts without the involvement and costs of intermediaries. Theoretically, it is a fair, accessible, and open digital marketplace. However, in reality, this is not always the case.

Fintech companies use DeFi technology to provide savings accounts, loans, securities trading, and insurance. Additionally, people can use DeFi services and dApps in coins, stablecoins, tokens, digital wallets, DeFi mining (liquidity mining), yield farming, staking, trading, borrowing, lending, and saving using smart contracts.</p>
              </div> */}
              </div>
              <br></br>
              <div className="row gy-5 gy-md-6" id="Cryptocurrency">
                <div className="col-xl-6">
                <img
            className="imgs"
            src={crypto}
            alt="light"
          />

                </div>
              <div className="col-xl-6">
                <div className="earn_crypto__cardone rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                  <div className="d-flex align-items-start justify-content-between flex-wrap gap-3 mb-8 mb-sm-10 mb-md-15">
                    <div>
                      <h2 className="mb-6 p1-color">
                      Token Allocation
                      </h2>
                    </div>
                    <p>
                    - 40% of total supply allocated for sale.<br></br>
                    - 25% of tokens distributed to Uniswap and 25% allocated to centralized exchanges.<br></br>
                    - 10% of tokens to be burned or donated to 50,000 wallets.<br></br>

                    Total Supply: 100,000,000,000,000 USED<br></br>

                    Presale - (40,000,000,000,000 USED)<br></br>
                    Exchange listing - (50,000,000,000,000 USED)<br></br>
                    Rewards & Burn - (10,000,000,000,000 USED)<br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <>
          <FAQ />
        </>
      </>
    </div>
  );
};

export default EarnCrypto;
