import React, { useEffect, useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Level_Details = () => {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [level, setLevel] = useState(0);
  const [position, setPosition] = useState(0);

  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const Level_API = async () => {
    try {
      let responce = await API?.post(
        `/levelDetailsReport`,
        {
          uid: userId,
          searchid: search,
          fdate: "",
          tdate: "",
          status: 2,
          position: position,
          level: level,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("Level_API", responce);
      responce = responce.data.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          Registration_Date: item.registrationDate,
          uid: item.uid,
          // f_name: item.f_name,
          Position: item.position,
          level: item.leveltype,
          Status: item.status,
          Latest: item?.Latest,
          Gross: item?.Gross,
          Activation_Date: item.activationDate,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            clubAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Level_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User ID", accessor: "uid" },
      { Header: "Position", accessor: "Position" },
      { Header: "Registration_Date", accessor: "Registration_Date" },
      { Header: "Level", accessor: "level" },
      { Header: "Status", accessor: "Status" },
      { Header: "Latest Package", accessor: "Latest" },
      { Header: "Gross Package", accessor: "Gross" },
      { Header: "Activation Date", accessor: "Activation_Date" },
    ],
  });

  const handlePositionChange = (value) => {
    setPosition(value);
  };

  const handleLevelChange = (value) => {
    setLevel(value);
  };

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Level Details</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Level Details</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Level Details
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 mb-3">
            <div className="col-md-3">
              <label className="text-white fs-14">User Id</label>
              <input
                type="text"
                name="from_date"
                id="from_date"
                className="form-control text-white shadow-none text-white bgmain"
                placeholder="User ID"
                defaultValue=""
              />
            </div>
            <br />
            <br />
            <div className="col-md-3">
              <label className="text-white fs-14">Position</label>
              <select
                className="form-control shadow-none bgmain"
                id="status"
                style={{ color: "rgb(255, 255, 255)", background: "#797c13" }}
                value={position}
                onChange={(e) => handlePositionChange(e.target.value)}>
                <option
                  value=""
                  style={{
                    color: "rgb(255, 255, 255)",
                    background: "#797c13",
                  }}>
                  Select Position
                </option>
                <option
                  value={0}
                  style={{
                    color: "rgb(255, 255, 255)",
                    background: "#797c13",
                  }}>
                  All
                </option>
                <option
                  value={1}
                  style={{
                    color: "rgb(255, 255, 255)",
                    background: "#797c13",
                  }}>
                  Left
                </option>
                <option
                  value={2}
                  style={{
                    color: "rgb(255, 255, 255)",
                    background: "#797c13",
                  }}>
                  Right
                </option>
              </select>
            </div>
            <br />
            <br />
            <div className="col-md-3">
              <label className="text-white fs-14">Level Choose</label>
              <select
                className="form-control shadow-none bgmain"
                id="level"
                style={{ color: "rgb(255, 255, 255)", background: "#797c13" }}
                value={level}
                onChange={(e) => handleLevelChange(e.target.value)}>
                <option value={0}>All Level</option>
                <option value={1}>Level 1</option>
                <option value={2}>Level 2</option>
                <option value={3}>Level 3</option>
                <option value={4}>Level 4</option>
                <option value={5}>Level 5</option>
                <option value={6}>Level 6</option>
                <option value={7}>Level 7</option>
                <option value={8}>Level 8</option>
                <option value={9}>Level 9</option>
                <option value={10}>Level 10</option>
                <option value={11}>Level 11</option>
                <option value={12}>Level 12</option>
                <option value={13}>Level 13</option>
                <option value={14}>Level 14</option>
                <option value={15}>Level 15</option>
                {/* <option value={16}>Level 16</option>
                <option value={17}>Level 17</option>
                <option value={18}>Level 18</option>
                <option value={19}>Level 19</option>
                <option value={20}>Level 20</option> */}
              </select>
            </div>
            <br />
            <br />
            <div className="col-md-auto mt-1">
            <input
                type="submit"
                name="to_date"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none w-auto"
                defaultValue="Search"
                onClick={() => Level_API()}
              />
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 jashja">
            <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={referralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Level_Details;
