import React, { useEffect, useState } from "react";
import DashHeader from "../DashHeader/DashHeader";
import "./Adjustment.css";
import DashFooter from "../DashFooter/DashFooter";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";

const Adjustment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [dash, setdataArray] = useState([]);
  const DirectIncomeReport = async () => {
    try {
      let responce = await API?.post(
        `/adjustmentReport`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("adjustmentReport", responce);
      responce = responce.data.data[0];
      setdataArray(responce);
      
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    DirectIncomeReport();
  }, []);

  return (
    <div>
        <DashHeader />
      <div className="page-wrapper pt-5">
        <div className="page-content adjustmentsection">
          <div className="row">
            <div className="col-12">
              <div className="heading text-white fw-bold fs-18 mb-3">
                ADJUSTMENT[]
              </div>
            </div>
            <div className="col-12">
              <div className="card bg-transparent mb-0 py-2">
                <div className="card-body p-0 p-sm-2">
                  <div className="row mx-0 pb-0 gy-3 align-items-center">
                    <div className="col-md-4">
                      <div className="card mb-0 innercard">
                        <div className="card-body">
                          <div className="card-heading fw-bold text-white">
                            Total Investment
                          </div>
                          <div className="text-white subtext">
                            {dash.investamount}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-0 innercard">
                        <div className="card-body">
                          <div className="card-heading fw-bold text-white">
                            Adjustment
                          </div>
                          <div className="text-white subtext">
                          {dash.totaldeduction}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-0 innercard">
                        <div className="card-body">
                          <div className="card-heading fw-bold text-white">
                            Remaining
                          </div>
                          <div className="text-white subtext">
                          {dash.reamingamount}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Adjustment;
