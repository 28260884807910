import React from "react";
import homeicon4 from "../../assets/images/homeicon4.png";
import homeicon5 from "../../assets/images/homeicon5.png";
import homeicon3 from "../../assets/images/homeicon3.png";
import homeicon6 from "../../assets/images/homeicon6.png";
import homeicon2 from "../../assets/images/homeicon2.png";
import { Link } from "react-router-dom";
import Block from "../../assets/images/block.png";

const Markets = () => {
  return (
    <div>
       <section className="web3_product how_join bg9-color pt-120 pb-120" id="Blockchain">
        <div className="container">
          <div className="row gy-5 gy-md-6">
            <div className="col-md-7">
                <div className="how_join__title text-left mb-10 mb-md-15">
                  <h2 className="wow fadeInUp p1-color">Tokenomics</h2>
                  
                  {/* <h4 class="mb-1 text-left p1-color mt-3">
                  Welcome To 36T  Coin: Empowering The Future Of Finance
                  </h4> */}
                  <p className="mt-3">
                  Token name : REUSEDCOIN<br></br>
                  Symbol : USED<br></br>
                  Protocol : ERC20<br></br>
                  Bloclchain: Ethereum<br></br>
                  Token address:<br></br>
                  Contract audited:<br></br>
                  - Total Supply: 100,000,000,000,000 USED<br></br>
                  - Price: $0.000000001000 per USED<br></br>
                  - Initial Listing: 50% of total supply - Uniswap and Centralized exchange <br></br>


                  Buy tax<br></br>
                  2% Auto liquidity - Uniswap<br></br>
                  1% Marketing  wallet in ETH<br></br>
                  <br></br>

                  Sell tax<br></br>
                  2% Auto liquidty - Uniswap<br></br>
                  2% Marketing wallet in ETH<br></br>

                  </p>
                  <p className="text-white">Token Address <span><a className="text-white" href="https://etherscan.io/address/0x80A2fb252af608a64142Ba6736F4391d41E6f513">0x80A2fb252af608a64142Ba6736F4391d41E6f513</a></span></p>
                </div>
            </div>
            <div className="col-md-5">
              <center>
            <img
            className="imgs"
            src={Block}
            alt="light"
          />
          </center>
            </div>
            </div>
          </div>
      </section>
      {/* <section id="Future" className="market_more what_trending bg5-color pt-120 pb-120">
        <div className="container">
          <div className="row categories__header mb-4 mb-md-6">
            <div className="col-xl-7 col-lg-8 col-sm-8">
              <div className="categories__title d-flex align-items-center gap-5 gap-md-6 wow fadeInUp">
                <h2 className="mb-3 mb-md-4 p1-color">The Future of 36t </h2>
              </div>
                <p>The future of 36t  is a promising journey filled with innovation and expansion. As we look ahead, we envision 36t evolving into a leading digital asset, with a thriving ecosystem that continues to provide exciting NFT experiences, user-friendly tools, and enhanced security. </p>
            </div>
            <div className="col-xl-5 col-lg-4 col-sm-4 categories_top_btn categories_top_btntwo categories_top_btnthree categories_top_btnfour mt-6 mt-sm-0">
              <div className="slider-btn d-center justify-content-start justify-content-sm-end gap-4">
                <button
                  type="button"
                  aria-label="Previous slide"
                  className="ara-prev d-center cmn-btn third-alt px-2 py-1 rounded-5"
                  tabIndex={0}
                  aria-controls="swiper-wrapper-125102b10edbe70308"
                >
                  <i className="ti ti-chevron-left fs-four" />
                </button>
                <button
                  type="button"
                  aria-label="Next slide"
                  className="ara-next d-center cmn-btn px-2 py-1 rounded-5"
                  tabIndex={0}
                  aria-controls="swiper-wrapper-125102b10edbe70308"
                >
                  <i className="ti ti-chevron-right fs-four" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="singletab">
              
              <div className="swiper categories_top swiper-initialized swiper-horizontal">
                <div
                  className="swiper-wrapper"
                  id="swiper-wrapper-c49d4de651de3aad"
                  aria-live="off"
                  style={{
                    transform: "translate3d(-1650px, 0px, 0px)",
                    transitionDuration: "0ms",
                  }}
                >
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                    data-swiper-slide-index={0}
                    role="group"
                    aria-label="1 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="d-flex align-items-center">
                            <h4>Engaging NFT Activities</h4>
                          </div>
                          <div className="text-start">
                            <h4 className="mb-1">Engaging NFT Activities</h4>
                            <span className="mb-5 mb-md-6 d-block ">
                              Lido Dao
                            </span>
                          </div>
                        </div>
                        <span className="fs-one p1-color fw_500 mb-1 d-block ">
                          11.31%
                        </span>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                          Apy
                        </span>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <span>TVL</span>
                          <span>$4.96 M</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-9 mb-md-12">
                          <span>Network</span>
                          <span>Ethereum</span>
                        </div>
                        <Link
                          to="/Staking"
                          className="cmn-btn third-alt py-3 px-5 px-md-6 w-100 "
                        >
                          Stack
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                    data-swiper-slide-index={1}
                    role="group"
                    aria-label="2 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="d-flex align-items-center">
                            <img
                              className="mb-3 mb-md-4"
                              src={homeicon4}
                              alt="icon"
                            />
                          </div>
                          <div className="text-start">
                            <h3 className="mb-1">cvxeth</h3>
                            <span className="mb-5 mb-md-6 d-block ">curve</span>
                          </div>
                        </div>
                        <span className="fs-one p1-color fw_500 mb-1 d-block ">
                          9.71%
                        </span>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                          Apy
                        </span>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <span>TVL</span>
                          <span>$16.66 M</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-9 mb-md-12">
                          <span>Network</span>
                          <span>Ethereum</span>
                        </div>
                        <Link
                          to="/Staking"
                          className="cmn-btn third-alt py-3 px-5 px-md-6 w-100 "
                        >
                          Stack
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="text-start">
                            <h3 className="mb-1">GLP</h3>
                            <span className="mb-5 mb-md-6 d-block ">gmx</span>
                          </div>
                        </div>
                        <span className="fs-one p1-color fw_500 mb-1 d-block ">
                          7.79%
                        </span>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                          Apy
                        </span>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <span>TVL</span>
                          <span>$4.96 M</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-9 mb-md-12">
                          <span>Network</span>
                          <span>Ethereum</span>
                        </div>
                        <Link
                          to="/Staking"
                          className="cmn-btn third-alt py-3 px-5 px-md-6 w-100 "
                        >
                          Stack
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={3}
                    role="group"
                    aria-label="4 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="text-start">
                            <h4 className="mb-1">Engaging NFT Activities</h4>
                            <span className="mb-5 mb-md-6 d-block ">
                              Lido Dao
                            </span>
                          </div>
                        </div>
                        <span className="fs-one p1-color fw_500 mb-1 d-block ">
                          11.31%
                        </span>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                          Apy
                        </span>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <span>TVL</span>
                          <span>$43.96 M</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-9 mb-md-12">
                          <span>Network</span>
                          <span>Ethereum</span>
                        </div>
                        <Link
                          to="/Staking"
                          className="cmn-btn third-alt py-3 px-5 px-md-6 w-100 "
                        >
                          Stack
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-prev"
                    data-swiper-slide-index={0}
                    role="group"
                    aria-label="1 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-6  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="text-start">
                            <br></br>
                            <h4 className="mb-1 text-center p1-color">
                              Sustainability Focus
                            </h4>
                          </div>
                        </div>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                        It's committed to eco-friendly blockchain practices, setting a green standard.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-active"
                    data-swiper-slide-index={1}
                    role="group"
                    aria-label="2 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="text-start">
                            <h4 className="mb-1 text-center p1-color">
                            Educational Hub
                            </h4>
                          </div>
                        </div>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                        36t empowers users with blockchain knowledge, promoting informed participation.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-next"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="text-start">
                            <h4 className="mb-1 text-center p1-color">
                            Social Impact
                            </h4>
                          </div>
                        </div>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                        Possible initiatives to harness blockchain for positive social change, making it socially responsible.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide"
                    data-swiper-slide-index={3}
                    role="group"
                    aria-label="4 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-6  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="text-start">
                            <h4 className="mb-1 text-center p1-color">
                              Engaging NFT Activities
                            </h4>
                          </div>
                        </div>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                          36t offers interactive NFT experiences like horse
                          racing and breeding, making it stand out.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                    data-swiper-slide-index={0}
                    role="group"
                    aria-label="1 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="text-start">
                            <h4 className="mb-1 text-center p1-color">
                              Engaging NFT Activities
                            </h4>
                          </div>
                        </div>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                          36t offers interactive NFT experiences like horse
                          racing and breeding, making it stand out.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                    data-swiper-slide-index={1}
                    role="group"
                    aria-label="2 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="text-start">
                            <h4 className="mb-1 text-center p1-color">
                              Engaging NFT Activities
                            </h4>
                          </div>
                        </div>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                          36t offers interactive NFT experiences like horse
                          racing and breeding, making it stand out.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="text-start">
                            <h4 className="mb-1 text-center p1-color">
                              Engaging NFT Activities
                            </h4>
                          </div>
                        </div>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                          36t offers interactive NFT experiences like horse
                          racing and breeding, making it stand out.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={3}
                    role="group"
                    aria-label="4 / 4"
                    style={{ width: 306, marginRight: 24 }}
                  >
                    <div className="items-wrapper">
                      <div className="market_more__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color rounded-3">
                        <div className="d-flex align-items-center justify-content-center gap-3 mb-8 mb-md-10">
                          <div className="text-start">
                            <h4 className="mb-1 text-center p1-color">
                              Engaging NFT Activities
                            </h4>
                          </div>
                        </div>
                        <span className="mb-8 mb-md-10 d-block fs-five">
                          36t offers interactive NFT experiences like horse
                          racing and breeding, making it stand out.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  className="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Markets;
