import React, { useEffect, useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useSelector } from "react-redux";
import { API } from "../../Utility/API";

const columns = [
  { Header: "S.No", accessor: "sr" },
  { Header: "User Id", accessor: "sid" },
  { Header: "Amount", accessor: "Amount" },
  { Header: "Date", accessor: "Date" },
  { Header: "Remark", accessor: "Remark" },
  // { Header: "Type", accessor: "Type" },
  // { Header: "Hash", accessor: "Hash" },
];
const Activate_Upgrade_History = () => {
  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  const Activate_Upgrade_History_API = async () => {
    try {
      let responce = await API?.post(
        `/activationHistory`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
          type: 1,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce?.data?.data[0];
      // console.log("activationHistory", responce);
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item?.row,
          sid: item?.uid[0],
          Date: item?.dd,
          Remark: item?.remark,
          Amount: item?.Amount,
          Type: item?.idtype,
          Hash:  (
            <a
              className="link-light"
              href={`https://bscscan.com/tx/${item?.pinnumber}`}
              target="_blank"
            >
              View Txn
            </a>
          ),
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  useEffect(() => {
    Activate_Upgrade_History_API();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper px-0">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  Activate/Upgrade History
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Activate</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Upgrade History
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 jashja">
              <Table data={[...currentPost]} columns={columns} />
              <Table_Buttons
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                  handleRowsPerPageChange={setlistPerpage}
                />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Activate_Upgrade_History;
