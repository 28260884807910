import React, { useState, useEffect } from "react";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { loadWeb3 } from "../../Utility/contract";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";
import {
  Abi_Main,
  Abi_USDT,
  ContractAddress_Main,
  ContractAddress_USDT,
} from "../../Utility/ContractAddress";
import { Spinner } from "react-bootstrap";

const Activate_Upgrade = () => {
 // Loader component
  const Loader = () => (
    <div className="loader text-center">
      <Spinner animation="border" variant="primary" />
      <p>Loading...</p>
    </div>
  );
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwtToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [Amount, setAmount] = useState(0);
  let [spiner, setspiner] = useState(false);
  // const [Wallet_Address, setWallet_Address] = useState("");
  const [TopupStatus, setTopupStatus] = useState("0");
  const [loading, setloading] = useState(false);
  let [USDT, setUSDT] = useState(0);


  const Calculatevalue = (val) => {
    const Addvalues = val;
    const TotalValue = parseFloat(Addvalues) + parseFloat(Amount);
    setAmount(TotalValue);
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDashboard?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];
      // console.log("GetUserDetails", responce);

      // setWallet_Address(responce.btcaddress);
      setTopupStatus(responce.top_up);
      setUSDT(responce.Net_ActivtionFunds);
    } catch (e) {
      //  console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(updateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const ActivationUpgrade = async (tstatus) => {
    try {
      if (Amount != 0) {
        if (parseFloat(USDT) < parseFloat(Amount)) {
          toast.error("Insufficient USDT Balance");
          setspiner(false);
          return;
        } else {
          setspiner(true);
        }

        if (parseFloat(Amount) < parseFloat(50)) {
          toast.error("You can activate your Id with Minimum 20 USDT");
          setspiner(false);
          return;
        } else {
          setspiner(true);
        }

        if (parseInt(tstatus) === 1) {
          let res = await API.post(
            "activation",
            {
              uid: user,
              referby: user,
              usd: Amount,
              token: Amount,
              bnb: Amount,
              txn: "",
              remark:"",
            },
            {
              headers: {
                Authorization: `${jwt}`,
                "Content-Type": "application/json",
              },
            }
          );

          console.log("res-->", res);
          if (res.data.data == "successfully activate !!") {
            toast.success("Successfully Activated !!");
            window.location.reload();
            setspiner(false);
          }
        } else if (parseInt(tstatus) === 2) {
          let res1 = await API.post(
            "upgradation",
            {
              uid: user,
              referby: user,
              usd: Amount,
              token: Amount,
              bnb: Amount,
              txn: "",
              remark:"",
            },
            {
              headers: {
                Authorization: `${jwt}`,
                "Content-Type": "application/json",
              },
            }
          );

          console.log("res-->", res1);
          if (res1.data.data == "Successfully Upgrade !!") {
            toast.success("Successfully Upgrade !!");
            window.location.reload();
            setspiner(false);
          }
        }

        setspiner(false);
      }
    } catch (e) {
      // console.log("error while calling fuction sell", err);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(updateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
      setspiner(false);
    }
    // setloader(false)
  };

  useEffect(() => {
    GetUserDetails();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                {parseInt(TopupStatus) === 0 ? "Activate" : "Upgrade"}
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Activate</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Activate / Upgrade
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100">
                    <h4 className="w-100 text-center pt-2">
                    Available USDT : {USDT || 0} USDT
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0">
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>Enter USD Amount</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              id="amountInput"
                              value={Amount}
                              readOnly
                              defaultValue={0}
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-12 mt-3 text-center">
                            {/* <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => Calculatevalue(10)}
                            >
                              $ 10
                            </div> */}
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => Calculatevalue(50)}
                            >
                              $ 50
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => Calculatevalue(100)}
                            >
                              $ 100
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => Calculatevalue(500)}
                            >
                              $ 500
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => Calculatevalue(1000)}
                            >
                              $ 1000
                            </div>
                          </div>
                          <div className="col-12 text-center py-3">
                            <button
                              className="btn btn-Green py-2 px-5 restart"
                              onClick={() => setAmount(0)}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mx-0">
                          <div className="col-sm-6 row align-items-center justify-content-center">
                            {loading == true ? (
                              <>
                                <Loader />
                              </>
                            ) : (
                              <>
                                <button
                                    className="btn btn_hxn withdraw_btn w-100 py-3 shadow-none"
                                    onClick={() => {
                                      if (parseInt(TopupStatus) === 0) {
                                        ActivationUpgrade(1);
                                      } else {
                                        ActivationUpgrade(2);
                                      }
                                    }}
                                  >
                                    {parseInt(TopupStatus) === 0
                                ? "Activate"
                                : "Upgrade"}
                                  </button>
                              </>
                            )}
                          </div>
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                              Note: Activation Amount Should Be Greater Than $50.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Activate_Upgrade;