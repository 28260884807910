import React from "react";
import cryptocurrency56304 from "../../assets/images/cryptocurrency56304.png";
import tableicon3 from "../../assets/images/tableicon3.png";
import cryptocurrency from "../../assets/images/cryptocurrency.png";
import { Link } from "react-router-dom";

const Gettt = () => {
  return (
    <div>
      <>
        <section className="get_started pt-120 pb-120 bg7-colo">
          <div className="container">
            <div className="row justify-content-center justify-content-sm-between align-items-center gy-5 gy-md-6 mb-10 mb-mb-15 pb-8 pb-md-10">
              <div className="col-lg-5 col-lg-5 col-xxl-4">
                <h2 className="text-center text-sm-start wow fadeInUp p1-color">
                  How to get started
                </h2>
              </div>
              {/* <div className="col-lg-7 col-lg-6 col-xxl-5">
                <p className="roboto text-center text-sm-start wow fadeInUp">
                  Our comprehensive cybersecurity platform, driven by artificial
                  intelligence, not only safeguards your organization but also
                  educates your workforce.
                </p>
              </div> */}
            </div>
            <div className="row bg1-color rounded-4">
              <div className="col-md-6 col-xl-3 col-xxl-3 p-xxl-0">
                <div className="get_started__item rounded-3 text-center position-relative wow fadeInUp">
                  <span className="get_started__item-icn py-3 px-4 rounded-5 bg1-color mb-5 mb-md-6">
                    <i className="ti ti-user fs-four" />
                  </span>
                  <h4 className="p1-color mb-5 mb-md-6">Mission Statement</h4>
                  <span className="roboto mb-8 mb-mb-10 d-block">
                    Our mission is to introduce a seamless, automated  experience utilizing the power of smart contracts.
                    This initiative is designed to redefine the way investors
                    and entrepreneurs engage, making
                    transactions transparent, secure, and immediate.
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 col-xxl-3 p-xxl-0">
                <div className="get_started__item rounded-3 text-center position-relative wow fadeInUp">
                  <span className="get_started__item-icn py-3 px-4 rounded-5 bg1-color mb-5 mb-md-6">
                    <i className="ti ti-shield-filled fs-four" />
                  </span>
                  <h4 className="p1-color mb-5 mb-md-6">Value Proposition</h4>
                  <span className="roboto mb-8 mb-mb-10 d-block">
                    The club offers a direct financial incentive for membership
                    via an automated smart contract system. This system ensures
                    that payments are made directly to members' TronLink wallets
                    without delay, negating the need for intermediary oversight
                    and enhancing transaction security.
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 col-xxl-3 p-xxl-0">
                <div className="get_started__item rounded-3 text-center position-relative wow fadeInUp">
                  <span className="get_started__item-icn py-3 px-4 rounded-5 bg1-color mb-5 mb-md-6">
                    <i className="ti ti-home-dollar fs-four" />
                  </span>
                  <h4 className="p1-color mb-5 mb-md-6">
                  Decentralized Crypto Smart Contracts
                  </h4>
                  <span className="roboto mb-8 mb-mb-10 d-block">
                  Smart contracts are at the heart of the club's functionality. These contracts are predefined with conditions that automatically execute based on the achievement of set milestones within the crowd funding process.
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 col-xxl-3 p-xxl-0">
                <div className="get_started__item rounded-3 text-center position-relative wow fadeInUp">
                  <span className="get_started__item-icn py-3 px-4 rounded-5 bg1-color mb-5 mb-md-6">
                    <i className="ti ti-user fs-four" />
                  </span>
                  <h4 className="p1-color mb-5 mb-md-6">Innovative Financial Models</h4>
                  <span className="roboto mb-8 mb-mb-10 d-block">
                  Our platform is at the vanguard of integrating DeFi innovations with tangible real-world utility
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="discover_web3 pools_tables pt-120 pb-120 bg9-color">
          <div className="container">
            <div className="row">
              <div className="discover_web3__title pools_table__title mb-5 mb-md-6 d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap gap-5">
                <div className="discover_web3__title-left">
                  <h2 className="mb-5 mb-md-6 wow fadeInUp">Discover Web3</h2>
                  <p className="roboto wow fadeInUp">
                    Access DApps via the Bybit Wallet extension or the
                    respective mobile app
                  </p>
                </div>
                <Link
                  to="/Staking"
                  className="cmn-btn py-2 py-md-3 px-5 px-md-6 wow fadeInUp"
                >
                  VIEW ALL
                </Link>
              </div>
              <div className="discover_web3__part pools_table__part">
                <div className="singletab">
                  <ul className="tablinks d-flex align-items-center gap-5 gap-sm-10 gap-md-15 gap-lg-19 mb-6 mb-md-8 wow fadeInUp">
                    <li className="nav-links">
                      <button className="tablink clickable-active">Defi</button>
                    </li>
                    <li className="nav-links">
                      <button className="tablink clickable-active">
                        GameFi
                      </button>
                    </li>
                    <li className="nav-links">
                      <button className="tablink clickable-active">NFT</button>
                    </li>
                  </ul>
                  <div className="tabcontents wow fadeInUp">
                    <div className="tabitem active overflow-auto">
                      <div className="pools_table__totalitem">
                        <table>
                          <tbody>
                            <tr>
                              <th>Rankings</th>
                              <th>Blockchain</th>
                              <th>Token Price</th>
                              <th>24H Volume</th>
                              <th>Market Cap</th>
                              <th>TVL</th>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>1</span>
                                    <img
                                      src={cryptocurrency56304}
                                      alt="Icons"
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <a
                                      href="staking-details.html"
                                      className="roboto fw-bold"
                                    >
                                      Milton Wagner
                                    </a>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$936.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$218 M</td>
                              <td>$79.5 B</td>
                              <td>005 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>2</span>
                                    <img src={cryptocurrency} alt="Icons" />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Calvin Peters
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$215.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$141 M</td>
                              <td>$147.5 B</td>
                              <td>$255 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>3</span>
                                    <img
                                      src={cryptocurrency56304}
                                      alt="Icons"
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Mason Patton
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$912.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$155 M</td>
                              <td>$28.5 B</td>
                              <td>392 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>4</span>
                                    <img src={cryptocurrency} alt="Icons" />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Alfred Kelley
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$640.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$198 M</td>
                              <td>$215.5 B</td>
                              <td>$631 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>5</span>
                                    <img
                                      src={cryptocurrency56304}
                                      alt="Icons"
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Alejandro Hogan
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$198.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$82 M</td>
                              <td>330.59 B</td>
                              <td>912 B</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="tabitem overflow-auto">
                      <div className="pools_table__totalitem">
                        <table>
                          <tbody>
                            <tr>
                              <th>Rankings</th>
                              <th>Blockchain</th>
                              <th>Token Price</th>
                              <th>24H Volume</th>
                              <th>Market Cap</th>
                              <th>TVL</th>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>1</span>
                                    <img
                                      src={cryptocurrency56304}
                                      alt="Icons"
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Milton Wagner
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$936.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$218 M</td>
                              <td>$79.5 B</td>
                              <td>005 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>2</span>
                                    <img src={cryptocurrency} alt="Icons" />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Calvin Peters
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$215.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$141 M</td>
                              <td>$147.5 B</td>
                              <td>$255 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>3</span>
                                    <img
                                      src={cryptocurrency56304}
                                      alt="Icons"
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Mason Patton
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$912.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$155 M</td>
                              <td>$28.5 B</td>
                              <td>392 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>4</span>
                                    <img src={cryptocurrency} alt="Icons" />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Alfred Kelley
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$640.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$198 M</td>
                              <td>$215.5 B</td>
                              <td>$631 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>5</span>
                                    <img
                                      src={cryptocurrency56304}
                                      alt="Icons"
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Alejandro Hogan
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$198.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$82 M</td>
                              <td>330.59 B</td>
                              <td>912 B</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="tabitem overflow-auto">
                      <div className="pools_table__totalitem">
                        <table>
                          <tbody>
                            <tr>
                              <th>Rankings</th>
                              <th>Blockchain</th>
                              <th>Token Price</th>
                              <th>24H Volume</th>
                              <th>Market Cap</th>
                              <th>TVL</th>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>1</span>
                                    <img
                                      src={cryptocurrency56304}
                                      alt="Icons"
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Milton Wagner
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$936.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$218 M</td>
                              <td>$79.5 B</td>
                              <td>005 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>2</span>
                                    <img src={cryptocurrency} alt="Icons" />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Calvin Peters
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$215.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$141 M</td>
                              <td>$147.5 B</td>
                              <td>$255 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>3</span>
                                    <img
                                      src={cryptocurrency56304}
                                      alt="Icons"
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Mason Patton
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$912.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$155 M</td>
                              <td>$28.5 B</td>
                              <td>392 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>4</span>
                                    <img src={cryptocurrency} alt="Icons" />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Alfred Kelley
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$640.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$198 M</td>
                              <td>$215.5 B</td>
                              <td>$631 B</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                  <div className="d-flex align-items-center gap-4">
                                    <span>5</span>
                                    <img
                                      src={cryptocurrency56304}
                                      alt="Icons"
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="roboto fw-bold">
                                      Alejandro Hogan
                                    </span>
                                    <span className="roboto">LDO</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={tableicon3} alt="Icons" />
                                  <span>Ethereum</span>
                                </div>
                              </td>
                              <td>
                                <span>$198.7</span>
                                <div className="d-flex align-items-center">
                                  <span className="fs-seven p1-color">
                                    +4.65%
                                  </span>
                                  <i className="ti ti-arrow-narrow-up fs-five p1-color" />
                                </div>
                              </td>
                              <td>$82 M</td>
                              <td>330.59 B</td>
                              <td>912 B</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </>
    </div>
  );
};

export default Gettt;
