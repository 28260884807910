import React from "react";
import logo from "../../assets/images/logo.png";
import dog from "../../assets/images/dog.png";
import wallet_connect from "../../assets/images/wallet_connect.png";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

const Header = () => {
  return (
    <div>
      <Navbar  collapseOnSelect expand="lg" className="bgHxnxnxn">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} className="logo loggHXN" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link className="lenkxn" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="lenkxn" href="#about">
                About us
              </Nav.Link>
              <Nav.Link className="lenkxn" href="#Blockchain">
              Tokenimics
              </Nav.Link>
              <Nav.Link className="lenkxn" href="#Cryptocurrency">
               Token Allocation
              </Nav.Link>

              <Nav.Link className="lenkxn" href="https://reusedcoin.gitbook.io/reusedcoin-whitepaper" target="_blank">
               White Paper
              </Nav.Link>
              {/* <Nav.Link className="lenkxn" href="#Defi">
                Defi
              </Nav.Link>
              <Nav.Link className="lenkxn" href="#Cryptocurrency">
              Cryptocurrency
              </Nav.Link> */}
              <Nav.Link className="lenkxn" href="#FAQs">
                FAQs
              </Nav.Link>
              <Nav.Link className="lenkxn" href="#Contact">
                Contact
              </Nav.Link>
              <Nav.Link href="https://www.pinksale.finance/launchpad/0x8c13Bf82255229dc09436543D5b5717E52ee9828?chain=ETH"  target="_blank" className="lenkxn mjaqhsduqs btx">
                Buy Used
              </Nav.Link>
              {/* <Nav.Link href="/Registration" className="lenkxn btx">
                Register
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
