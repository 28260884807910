import React from "react";
import hero_shape from "../../assets/images/hero_shape.png";
import upcomingcard7 from "../../assets/images/upcomingcard7.png";
import round from "../../assets/images/round.png";
import lightone from "../../assets/images/lightone.png";
import lighttwo from "../../assets/images/lighttwo.png";
import coinbase from "../../assets/images/coinbase.png";
import bitfinex from "../../assets/images/bitfinex.png";
import binance from "../../assets/images/binance.png";
import ripple from "../../assets/images/ripple.png";
import steemit from "../../assets/images/steemit.png";
import About from "../../assets/images/about.png";
import start from "../../assets/images/start.gif";
import subscription from "../../assets/images/subscription.png";
import snapshot from "../../assets/images/snapshot.png";
import lottery from "../../assets/images/lottery.png";
import redemption from "../../assets/images/redemption.png";
import Markets from "../Markets/Markets";
import Gettt from "../Gettt/Gettt";
import EarnCrypto from "../EarnCrypto/EarnCrypto";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Hero = () => {
  return (
    <div>
      <Header />
      <section className="hero_area pt-120 pb-16 position-relative">
        <div className="container z-1">
          <div className="row justify-content-center mt-8 mt-sm-13 mt-md-0">
            <div className="col-xl-9">
              <div className="hero_area__content pt-17 pt-sm-20 pt-lg-0 text-center">
                <span className="fs-five py-2 px-3 px-sm-5 mb-4 wow fadeInUp">
                Reused Coin
                </span>
                <h1 className="display-three mb-5 mb-md-6 wow fadeInUp">
                The Token for a Liberated World!
                </h1>
                <p className="mb-8 mb-md-10 wow fadeInUp">
                Reusedcoin (USED) is more than just a cryptocurrency; it's a gateway to a world of opportunity. With a free platform accessible to all, Reusedcoin breaks down barriers and eliminates costly intermediaries. Backed by no company and supporting no one but the public, Reusedcoin is a beacon of freedom and accessibility. Join us in revolutionizing the crypto experience, where empowerment and innovation pave the way for a brighter future for all.
                </p>
                <div className="d-flex align-items-center justify-content-center flex-wrap gap-4 gap-md-6 mb-10 mb-md-13 wow fadeInUp">
                  <a
                    className="hero_area__content-btnone cmn-btn px-6 px-md-8 py-3 d-center gap-3"
                    href="https://github.com/cyberscope-io/audits/blob/main/used/audit.pdf
                    " target="_blank"
                  >
                    AUdit report
                    <i className="ti ti-chevron-right fs-five px-1 bg4-color p6-color rounded-3 fw-bolder" />
                  </a>
                  <Link
                    className="hero_area__content-btntwo cmn-btn third-alt px-6 px-md-8 py-3 d-center gap-3"
                    to="https://www.pinksale.finance/launchpad/0x8c13Bf82255229dc09436543D5b5717E52ee9828?chain=ETH" target="_blank"
                  >
                    Buy USED
                    <i className="ti ti-chevron-right fs-five px-1 bg2-color rounded-3 fw-bolder" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 my-7 wow fadeInUp d-flex flex-column align-items-center justify-content-center">
              <div className="hero_area__thumb wow fadeInUp position-relative">
                <img
                  className="rounded-5"
                  src={start}
                  alt="Hero Banner"
                  style={{width:"400px"}}
                />
              </div>
              <h4 className="py-8 wow fadeInUp p1-color">Empowering Everyone, Everywhere: Reusedcoin - The Token for a Liberated World!</h4>
              <p>Reusedcoin (USED) thrives on community-driven principles, embodying the spirit of decentralization and collective empowerment. As a token born from the grassroots, it embraces the ethos of inclusivity and collaboration, inviting enthusiasts worldwide to contribute to its vibrant ecosystem. While it maintains independence from corporate affiliations, Reusedcoin celebrates the active involvement of its diverse community members who drive its growth and development. With no formal team in place, Reusedcoin represents a beacon of innovation fueled by the collective creativity and passion of its users. Despite its playful origins as a meme coin-token, Reusedcoin fosters a dynamic and engaging environment, where every participant plays a vital role in shaping its journey within the crypto space.
               </p><br />
             
            </div>
          </div>
        </div>
        <div className="hero_area__shape">
          <img
            className="position-absolute rotated_animattwo"
            src={hero_shape}
            alt="Shape"
          />
        </div>
        <div className="hero_area__lishtone">
          <img
            className="position-absolute opacity-75"
            src={lightone}
            alt="light"
          />
        </div>
        <div className="hero_area__lishttwo">
          <img
            className="position-absolute opacity-75"
            src={lighttwo}
            alt="light"
          />
        </div>
      </section>

      <section className="web3_product how_join bg9-color pt-120 pb-120" id="about">
        <div className="container">
          <div className="row gy-5 gy-md-6">
            <div className="col-md-6">
                <div className="how_join__title text-left mb-10 mb-md-15">
                  <h2 className="wow fadeInUp p1-color">ABOUT US</h2>
                  
                  {/* <h4 class="mb-1 text-left p1-color mt-3">
                  Vision & Mission
                  </h4> */}
                  <p>
                  JUSTREUSED - The platform, slated to launch soon with a user-friendly interface, aims to extend the lifespan of used items while initially targeting Europe before expanding globally. As ReusedCoin evolves, it will transition from an equity token to a utility token within its ecosystem, fostering transactions, user engagement, and participation rewards. However, it's essential to note that investing in cryptocurrencies carries inherent risks, and individuals should conduct thorough research and seek professional advice before participating.
                  </p>
                </div>
            </div>
            <div className="col-md-6">
              <center>
            <img
            className="imgs"
            src={About}
            alt="light"
          />
          </center>
            </div>
            </div>
          </div>
      </section>

      <section className="web3_product how_join bg9-color pt-120 pb-120">
        <div className="container">
          <div className="row gy-5 gy-md-6">
            <div className="how_join__title text-center mb-10 mb-md-15">
              <h2 className="wow fadeInUp p1-color">What Sets Us Apart?</h2>
            </div>
            <div className="col-sm-6 col-lg-3 col-xxl-3">
              <div className="web3_product__item how_join__item  rounded-3 br2 position-relative wow fadeInUp">
                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                  <img src={subscription} alt="Icons" />
                </div>
                <h4 className="mb-4 mb-md-5">Fair Launch</h4>
                <p className="mb-6 mb-md-8">
                  With our community-driven, you can harness
                  the power of collective decision-making.
                </p>
                {/* <div className="web3_product__item-btn">
                  <a
                    className="cmn-btn third-alt px-3 py-2 rounded-5"
                    href="#"
                  >
                    <i className="ti ti-arrow-up-right fs-four" />
                  </a>
                </div> */}
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 col-xxl-3">
              <div className="web3_product__item how_join__item rounded-3 br2 position-relative wow fadeInUp">
                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                  <img src={snapshot} alt="Icons" />
                </div>
                <h4 className="mb-4 mb-md-5">Fair Distribution</h4>
                <p className="mb-6 mb-md-8">
                  To ensure a level playing field for all participants, we
                  prioritise equitable token distribution.
                </p>
                
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 col-xxl-3">
              <div className="web3_product__item how_join__item rounded-3 br2 position-relative wow fadeInUp">
                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                  <img src={lottery} alt="Icons" />
                </div>
                <h4 className="mb-4 mb-md-5">Transparency</h4>
                <p className="mb-6 mb-md-8">
                  Our dedication to transparency fosters trust by providing a
                  clear picture of the token's history and allocations.
                </p>
                
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 col-xxl-3">
              <div className="web3_product__item how_join__item rounded-3 br2 position-relative wow fadeInUp">
                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                  <img src={redemption} alt="Icons" />
                </div>
                <h4 className="mb-4 mb-md-5">Engagement Rewards</h4>
                <p className="mb-6 mb-md-8">
                  Active participation is rewarded, fostering a thriving
                  community invested in the token's success.
                </p>
               
              </div>
            </div>
            {/* <div className="col-12">
              <div className="text-center mt-2 mt-md-4">
                <Link
                  to="/"
                  className="cmn-btn py-2 px-5 px-md-6 d-inline-flex justify-content-center align-items-center roboto"
                >
                  VIEW MORE <i className="ti ti-chevron-right fs-four" />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <Markets />
      <Gettt />
      <EarnCrypto />
      <Footer />
    </div>
  );
};

export default Hero;
