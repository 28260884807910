import React, { useEffect, useState } from "react";
import google_icon from "../../assets/images/google_icon.png";
import apple_icon from "../../assets/images/apple_icon.png";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Login.css";
import toast from "react-hot-toast";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import axios from "axios";
import { loadWeb3 } from "../../Utility/contract";
import { useDispatch } from "react-redux";

const Login = () => {
  let navigate = useNavigate();
  const [spinnerload, setspinnerload] = useState(false);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [LoginId, setLoginId] = useState("");
  const [connected, setconnected] = useState(true);
  const [ipAddress, setIP] = useState("");
  const dispatch = useDispatch();

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      let response = await axios.get("https://geolocation-db.com/json/");
      console.log("response Geolocation", response.data.IPv4);
      setIP(response.data.IPv4);
      let ipaddress = response.data.IPv4;

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("Please Connect Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setLoginId(acc);

        if (acc !== "") {
          let res = await API.post(`/login`, {
            password: acc,
            ipaddress: ipaddress,
          });
          // console.log("UserloginNew Auto", res);
          let UserID = res.data.data;
          if (res.data.data.result === "Successfull") {
            toast.success(`Login Successful`);
            console.log("loginmask", res.data.token);
            dispatch(
              updateAuth({
                clubAuth: true,
                userId: res.data.data.uid_output,
                jwtToken: res.data.token,
                ipAddress: ipaddress,
              })
            );
            navigate("/DashBoard");
          } else {
            toast.error(res.data.data.result);
          }
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const onSubmitHandler = async (data) => {
    // console.log("LoginId", LoginId);
    let acc = await loadWeb3();

    setspinnerload(true);
    let response = await axios.get("https://geolocation-db.com/json/");
    // console.log("response Geolocation", response.data.IPv4);
    setIP(response.data.IPv4);
    let ipaddress = response.data.IPv4;
    let res = await API.post(`/login`, {
      password: LoginId,
      ipaddress: ipaddress,
    });
    let UserID = res.data.data;
    if (res.data.data.result === "Successfull") {
      toast.success(res.data.data.result);
      // console.log("login", res);
      dispatch(
        updateAuth({
          clubAuth: true,
          userId: res.data.data.uid_output,
          jwtToken: res.data.token,
          ipAddress: ipaddress,
        })
      );
      navigate("/DashBoard");
    } else {
      toast.error(`Invalid User id or Address`);
      setspinnerload(false);
    }
    setspinnerload(false);
  };

  useEffect(() => {
    walletConnected();
    let intervalId = setInterval(walletConnected, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <div className="bgimage">
        <div className="container-fluid">
          <div className="container vh-100 ">
            <div className="row align-items-center justify-content-center h-100">
              <div className="col-sm-4">
                <div
                  className="card"
                  style={{ border: "2px solid rgb(81 118 5)" }}>
                  <div
                    className="card-body"
                    style={{ backgroundColor: "rgba(0, 97, 0, 0.28)" }}>
                    <div className="row mx-0 align-items-center justify-content-center gy-3">
                      <div className="col-12">
                        <div className="logoimage mx-auto">
                          <a href="/">
                          <img
                            className="w-100 h-100"
                            src="./assets/images/logo.png"
                            alt=""
                          />
                          </a>
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <div className="loginheading py-5">
                          Access Your Personal Account
                        </div>
                        <div
                          className="subtext"
                          style={{
                            color:
                              connected === "Wallet is Connected"
                                ? "green"
                                : "red",
                          }}>
                          {connected}
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        {/* <Link to="/DashBoard"> */}
                        <button
                          className="btn btnlogin"
                          // disabled
                          onClick={() => onSubmitHandler()}>
                          Authorized Login
                        </button>
                        {/* </Link> */}
                      </div>
                      <div className="col-12">
                        <div className="remembertext">
                          Remember to authorize with the correct address.View an
                          Account
                        </div>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          className="control-form"
                          placeholder="Please Enter ID or Address"
                          value={LoginId}
                          onChange={(e) => setLoginId(e.target.value)}
                          // readOnly
                        />
                      </div>
                      <div className="col-12">
                        <div className="remembertext">
                          Enter the Wallet Address
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="remembertext2">
                          Don’t have an account?{" "}
                          <Link
                            to="/Registration"
                            style={{ color: "rgb(250, 255, 44)" }}>
                            {" "}
                            Register
                          </Link>
                        </div>
                        <div className="remembertext2">
                          Copyright © 2024. All Rights Reserved.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
