import React from "react";

const FAQ = () => {
  return (
    <div>
      <section id="FAQs" className="rewards_faq pt-120 pb-120 position-relative">
        <div className="container">
          <div className="row align-items-center justify-content-center gy-8">
            <div className="col-xl-7">
              <div className="rewards_faq__content">
                <h2 className="mb-5 mb-md-6 wow fadeInUp p1-color">Frequently Asked Questions (FAQ) - Reusedcoin (USED)</h2>
                <p className="roboto mb-8 mb-md-10 wow fadeInUp">
                  Explore our FAQs for fast, informative answers to frequently
                  asked questions and common concerns.
                </p>
                <div className="accordion-section">
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       What is Reusedcoin (USED)?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        “ReusedCoin (USED) is a decentralized crypto token designed to revolutionize the exchange of used products and services through a secure and transparent platform. With a total supply of 100 trillion tokens and built on the ERC-20 standard, USED operates on a super deflationary model with buying and selling taxes allocated for platform development and marketing.”
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                         How does Reusedcoin work?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        Reusedcoin operates on the Ethereum blockchain as an ERC20 token. Users can acquire USED tokens through exchanges or participate in community-driven initiatives. These tokens can then be used within our platform to facilitate real estate transactions or held as an investment.

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       What is the vision behind Reusedcoin?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        The vision for Reusedcoin is to redefine the real estate market by promoting sustainability, accessibility, and community empowerment. We aim to create a global ecosystem where users can transact seamlessly, unlock new opportunities, and contribute to positive social and environmental impact.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       How is Reusedcoin different from traditional real estate transactions?

                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        Unlike traditional models, Reusedcoin eliminates the need for costly intermediaries, making real estate transactions more accessible and efficient. Our platform empowers users to engage directly with one another, fostering a sense of community and trust.

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       Is Reusedcoin associated with any corporation?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        No, Reusedcoin is not associated with any corporation. It operates on community-driven principles, with no formal team in place. Instead, it relies on the active participation of its diverse community members to drive its growth and development.

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                        How can I acquire Reusedcoin?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        You can acquire Reusedcoin (USED) through exchanges where it is listed. Additionally, you can participate in community-driven initiatives or receive tokens as rewards for contributing to the ecosystem.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       What is the total supply of Reusedcoin?

                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        The total supply of Reusedcoin is 100,000,000,000,000 USED. However, only a portion of this supply is initially available for sale, with the remainder allocated to liquidity, marketing, and community initiatives.
                        </p>
                      </div>
                    </div>
                  </div>
                
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       Can I expect financial returns from investing in Reusedcoin?

                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        Reusedcoin does not guarantee financial returns. It is intended for entertainment and educational purposes only. All investment decisions should be made at the individual's discretion.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                        How can I contribute to the Reusedcoin community?

                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        You can contribute to the Reusedcoin community by participating in discussions, proposing ideas for improvement, or getting involved in community-driven initiatives. Your input and engagement are essential to the success of the project.

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       Where can I find more information about Reusedcoin?

                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        For more information about Reusedcoin, including updates, announcements, and community events, you can visit our website, join our social media channels, or engage with our community forums.

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 wow fadeInUp">
                  <span>Can't see your question listed above?</span>
                  <a
                    href="javascript:void(0)"
                    className="d-flex align-items-center gap-1 p6-color astyle"
                  >
                    Visit our Help Center{" "}
                    <i className="ti ti-arrow-narrow-right fs-four mt-1" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="rewards_faq__thumb">
                <img
                  src="assets/images/faq-thumb.png"
                  className="max-un leftright-animation"
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Contact" className="rewards_faq pt-120 pb-120 position-relative d-none">
        <div className="container">
          <div className="row align-items-center justify-content-center gy-8">
            <div className="col-md-12">
            <h2 class="mb-2 p1-color text-center ">Contact Us</h2>
            <p className="text-center">Get In Touch</p>

             <form method="post" class="row cardSection px-sm-3 px-2 py-sm-4 py-3 gy-4">
                <div class="col-12 mt-2 mt-sm-0"><input class="form-control form-control-lg" type="text" placeholder="Your Name" aria-label=".form-control-lg example"/></div>
                <div class="col-12"><input class="form-control form-control-lg" type="email" placeholder="Your E-Mail" aria-label=".form-control-lg example"/></div>
                <div class="col-12"><input class="form-control form-control-lg" type="email" placeholder="Subject" aria-label=".form-control-lg example"/></div>
                <div class="col-12"><textarea placeholder="Message" class="form-control" id="exampleFormControlTextarea1" rows="6"></textarea></div>
              </form>
              <button class="formbtn btn py-2 px-4 fw-bold">Submit</button>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FAQ;
