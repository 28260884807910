import React, { useEffect, useState } from "react";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";
import toast from "react-hot-toast";
import { API } from "../../Utility/API";
import { loadWeb3 } from "../../Utility/contract";

const Buy_Token = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwtToken);
  let [Amount, setAmount] = useState(0);
  const [Wallet_Address, setWallet_Address] = useState("");
  const [dashData, setdashData] = useState([]);
  let [connected, setconnected] = useState("Wallet is locked");
  let [spiner, setspiner] = useState(false);
  const [TokenAmount, setTokenAmount] = useState(0);
  const [Buy_Rank_name, setBuy_Rank_name] = useState("");
  const [UserType, setUserType] = useState("");
  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDashboard?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];
      console.log("dashboardData-->", responce);
      setBuy_Rank_name(responce.Buy_Rank_name);
      setUserType(responce.Buy_Rank_name);

      setdashData(responce);
      setWallet_Address(responce.btcaddress);
    } catch (e) {
      //  console.log("userDetails error", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(updateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };

  const WalletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const CalculateValue = async (val) => {
    const Selvalues = val;
    const tvalue = parseFloat(Selvalues);
    setAmount(tvalue);
    setTokenAmount(tvalue * (1 / dashData.BuyRate));
  };

  const BuyToken = async () => {
    if (user === undefined || user === "" || user === null) {
      navigate("/");
    }
    let acc = await loadWeb3();
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet");
    } else if (acc === "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
    } else {
      if (Wallet_Address.toUpperCase() === acc.toUpperCase()) {
        if (Amount !== 0) {
          if (dashData.Net_buyFunds < Amount) {
            toast.error("Insufficient Buy Wallet Balance");
          } else if (UserType === "") {
            toast.error("Select User Type!!!");
          } else {
            setspiner(true);
            console.log("buycoin", "buycoin");
            try {
              let res = await API.post(
                "BuyToken",
                {
                  uid: user,
                  amount: Amount,
                  buyrank: UserType,
                },
                {
                  headers: {
                    Authorization: `${jwt}`,
                    "Content-Type": "application/json", // Adjust the content type as needed
                  },
                }
              );
              console.log("buycoin", res);

              if (res.data.data == "Buy Token Successfully") {
                toast.success("Buy Token Successfull");
                navigate("/dashboard");
                setspiner(false);
              } else {
                toast.error(res.data.data);
                setspiner(false);
              }

              setspiner(false);
            } catch (e) {
              // console.log("error while calling fuction ", err);
              if (
                e.response.data.showableMessage ==
                "Please login to access this resource"
              ) {
                dispatch(
                  updateAuth({ isAuth: false, userId: null, jwt_token: null })
                );
                navigate("/");
              }
              setspiner(false);
            }
          }
        } else {
          toast.error("Please Enter Amout First");
        }
      } else {
        toast.error("Invalid Wallet address");
      }
    }

    // setloader(false)
  };

  useEffect(() => {
    WalletConnected();
    GetUserDetails();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Buy Token</div>
                {/* <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Buy</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Buy Token
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100 flex-wrap ">
                    <h4 className="w-100 text-center pt-2">
                      Buy Wallet Balance : {dashData.Net_buyFunds}
                    </h4>
                    <p className="text-white fs-16 text-center w-100">
                      Buy Token Rate : {dashData.BuyRate}
                    </p>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0">
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-md-4 col-6">
                            <label>User Type</label>
                          </div>
                          <div className="col-md-8 col-6">
                            {Buy_Rank_name === "" ? (
                              <>
                                {" "}
                                <select
                                  id="type"
                                  className="form-control shadow-none"
                                  value={UserType}
                                  onChange={(e) => setUserType(e.target.value)}
                                >
                                  <option value="" disabled>
                                    Select User type
                                  </option>
                                  <option value="Invester">Invester</option>
                                  <option value="Promoter">Promoter</option>
                                </select>
                              </>
                            ) : (
                              <>
                                {" "}
                                <select
                                  id="type"
                                  className="form-control shadow-none"
                                  value={UserType}
                                  disabled="disabled"
                                  onChange={(e) => setUserType(e.target.value)}
                                >
                                  <option value="" disabled>
                                    Select User type
                                  </option>
                                  <option value="Investor">Investor</option>
                                  <option value="Promoter">Promoter</option>
                                </select>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-md-4 col-6">
                            <label>Enter USD Amount</label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="text"
                              className="form-control  shadow-none"
                              id="amountInput"
                              defaultValue={0}
                              value={Amount}
                              readOnly
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-7 mt-3 text-end">
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd mb-2"
                              onClick={() => CalculateValue(50)}
                            >
                              $ 50
                            </div>
                          </div>
                          <div className="col-5 text-start py-3">
                            <button
                              className="btn btn-Green py-2 px-5 restart"
                              onClick={() => CalculateValue(0)}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mx-0">
                          <div className="col-md-3"></div>
                          <div className="col-md-5">
                            <button
                              className="btn btn_hxn withdraw_btn w-100 py-3 shadow-none"
                              onClick={() => {
                                BuyToken();
                              }}
                            >
                              Buy Token
                            </button>
                          </div>
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0">
                              Buy Token Amount is $ 50.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Buy_Token;
