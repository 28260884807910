import React from "react";
import "./DashHeader.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { HiOutlineHome } from "react-icons/hi";
import { FaHardDrive } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa6";
import { LuFileEdit } from "react-icons/lu";
import { IoIosLock } from "react-icons/io";
import { MdSell } from "react-icons/md";
import logo from "../../assets/images/logo.png";
import id_red from "../../assets/images/id_red.png";
import id_green from "../../assets/images/id_green.png";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";
import icon from "../../assets/images/id.png";
import dummy from "../../assets/images/dummy.png";
const DashHeader = () => {
  const { userId, status } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  return (
    <div>
      <div className="topbar d-flex align-items-center">
        <nav className="navbar navbar-expand">
          <div className="topbar-logo-header">
            <div className="">
              <img src={logo} className="logo" alt="logo icon" />
            </div>
          </div>
          <div className="Id_Profile">
            <h6 className="six">
              ID:-
              {status === "Active" ? (
                <img src={icon} alt="" className="id_Inactive" />
              ) : (
                <img src={id_red} alt="" className="id_Inactive" />
              )}
              {userId}
            </h6>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a className="dahHeaderemg">
              <img src={dummy}/>
            </a>
          </div>
        </nav>
      </div>

      <Navbar
        collapseOnSelect
        expand="xl"
        className="bgNav brdbtm"
        style={{ background: "rgb(27 93 31)", top: "60px" }}
        fixed="top">
        <Container>
          <Navbar.Brand className="navbar_brandDash">
            <Link to="/DashBoard">
              <img
                src={logo}
                className="logo"
                alt="logo icon"
                style={{ width: "50px" }}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="align-items-center">
              <Link className="lenk" to="/DashBoard">
                <HiOutlineHome /> Dashboard
              </Link>

              <Link className="lenk" to="/Adjustment">
              <i class="fa fa-desktop" aria-hidden="true"></i> Adjusment
              </Link>

              <Link className="lenk" to="/Sell_Rate_Chart">
              <i class="fa fa-users" aria-hidden="true"></i> Buy/Sell Rate Chart
              </Link>

              {/* <span className="lenk">
                <div class="dropdown">
                  <button class="dropbtn">
                    <FaChartLine /> Rapid Fund
                  </button>
                  <div class="dropdown-content">
                    <Link to="/Add_Fund">User To User Rapid Fund Transfer</Link>
                    <Link to="/Fund_Report">Rapid Fund Transfer History</Link>
                    <Link to="/reciveFund_Report">Rapid Fund Received History</Link>
                  </div>
                </div>
              </span> */}
               <span className="lenk">
                <div class="dropdown">
                  <button class="dropbtn">
                    <FaChartLine /> Deposit
                  </button>
                  <div class="dropdown-content">
                    <Link to="/Deposite">Deposit Fund</Link>
                    <Link to="/Deposite_History">Deposit Fund History</Link>
                  </div>
                </div>
              </span>
              <span className="lenk">
                <div class="dropdown">
                  <button class="dropbtn">
                    <LuFileEdit /> Buy
                  </button>
                  <div class="dropdown-content">
                    <Link to="/Buy_Token">Buy Token</Link>
                    <Link to="/Buy_Token_Report">Buy Token Report</Link>
                  </div>
                </div>
              </span>
              <span className="lenk">
                <div class="dropdown">
                  <button class="dropbtn">
                  <i class="fa fa-cloud-upload" aria-hidden="true"></i> Activaion/Upgrade
                  </button>
                  <div class="dropdown-content">
                    <Link to="/Activate_Upgrade">Activaion/Upgrade</Link>
                    {/* <Link to="/Activate_Upgrade_Fund">
                      Activaion/Upgrade By Fund
                    </Link> */}
                    <Link to="/Activate_Upgrade_History">
                      Activaion History
                    </Link>
                  </div>
                </div>
              </span>
              
              <span className="lenk">
                <div class="dropdown">
                  <button class="dropbtn">
                    <MdSell /> Sell
                  </button>
                  <div class="dropdown-content">
                    <Link to="/Sell_Token">Sell Token</Link>
                    <Link to="/Sell_Token_Report">Sell Token Report</Link>
                    {/* <Link to="/Auto_Sell_Token_Report">
                      Auto Sell Token Report
                    </Link> */}
                  </div>
                </div>
              </span> 
              <span className="lenk">
                <div class="dropdown">
                  <button class="dropbtn">
                  <i class="fa fa-black-tie" aria-hidden="true"></i> Genealogy
                  </button>
                  <div class="dropdown-content">
                    <Link to="/Tree_View"> Tree View</Link>
                    <Link to="/Direct_User_List">Direct User List</Link>
                    <Link to="/Level_Details">Level Details</Link>
                    <Link to="/Downline">Downline</Link>
                    <Link to="/Direct_Leg_Bussiness">Direct Leg Bussiness</Link>
                  </div>
                </div>
              </span>
              <span className="lenk">
                <div class="dropdown">
                  <button class="dropbtn">
                    <LuFileEdit /> Reports
                  </button>
                  <div class="dropdown-content jhdcjsdqkijioj">
                    <Link to="/Direct_Earning_Income">Direct Earning</Link>
                    <Link to="/Binary_Earning_Report">Binary Earning</Link>
                    <Link to="/roi_income">ROI Income</Link>
                    <Link to="/roilevelincome">Roi Level Income</Link>
                    <Link to="/salary_income">Salary Income</Link>
                    {/* <Link to="/RapidFundIncomeReport">Rapid Fund Income</Link> */}
                    <Link to="/WithdrawlShare_Income">
                      WithdrawlShare Income
                    </Link>
                    <Link to="/ShortTermReward_incomeReport">
                      Short Term Reward income
                    </Link>
                    <Link to="/LongTermReward_incomeReport">
                      Long Term Reward income
                    </Link>
                  </div>
                </div>
              </span>
              <span className="lenk">
                <div class="dropdown">
                  <button class="dropbtn">
                  <i class="fa fa-university" aria-hidden="true"></i> Withdrawal
                  </button>
                  <div class="dropdown-content">
                    <Link to="/Withdrawal">Withdrawal</Link>
                    <Link to="/Withdrawal_History">Withdrawal History</Link>
                  </div>
                </div>
              </span>
              <Link
                className="lenk"
                to="/Login"
                onClick={() =>
                  dispatch(
                    updateAuth({
                      clubAuth: false,
                      userId: null,
                      jwtToken: null,
                      ipAddress: null,
                    })
                  )
                }>
                <IoIosLock /> Sign Out
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default DashHeader;
