import React, { useEffect, useState } from "react";
import "./DashboardMain.css";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";
import binaryincome from "../../assets/images/binaryincome.svg";
import Total_Income from "../../assets/images/Total_Income.svg";
import profile from "../../assets/images/profile.png";
import NetWithdrawalBalance from "../../assets/images/NetWithdrawalBalance.svg";
import Withdrawal_Amount from "../../assets/images/Withdrawal_Amount.svg";
import withincome from "../../assets/images/withincome.svg";
import MatrixCTOIncome from "../../assets/images/MatrixCTOIncome.svg";
import Direct_Income from "../../assets/images/Direct_Income.svg";
import matrixincome from "../../assets/images/matrixincome.svg";
import totaltoken from "../../assets/images/totaltoken.svg";
import buysale from "../../assets/images/buysale.svg";
import tokenbuy from "../../assets/images/tokenbuy.svg";
import tokenbuy2 from "../../assets/images/tokenbuy2.svg";
import total from "../../assets/images/total.png";
import ra from "../../assets/images/ra.png";
import to from "../../assets/images/to.svg";
import img1 from "../../assets/images/img3.png";
import DbodBuysellReport from "../Buy_Token_Report/DbodBuysellReport";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { loadWeb3 } from "../../Utility/contract";
import { API } from "../../Utility/API";
import { updateAuth, updateStatus } from "../../Redux/AuthSlice";
import { SetDashboardData } from "../../Redux/AuthSlice";
import Timer from "../Timer/Timer";
import dummy from "../../assets/images/dummy.png";
import Times from "../../assets/images/timer.png";
const DashboardMain = () => {
  const { userId, jwtToken, ipAddress } = useSelector((state) => state.Auth);
  // console.log("userId", userId);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let URL = window.location.origin;
  const LeftCopyAddressHandler = () => {
    const text = `${URL}/Registration?userid=${userId}&position=Left`;
    //  `${URL}/registration?userid=${user}`
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const RightCopyAddressHandler = () => {
    const text = `${URL}/Registration?userid=${userId}&position=Right`;

    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const [dash, setdataArray] = useState([]);

  const [walletAddress, setwalletAddress] = useState("ConnectWallet");
  // console.log("walletAddress", walletAddress);

  const Dashboard_API = async () => {
    try {
      let acc = await loadWeb3();
      setwalletAddress(acc);
      let responce = await API?.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];

      console.log("Dashboard res-->", responce);

      setdataArray(responce);
      dispatch(SetDashboardData(responce));
      if (responce.CurrentPackage > 0) {
        dispatch(updateStatus({ status: "Active" }));
      } else {
        dispatch(updateStatus({ status: "In-Active" }));
      }
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            clubAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };
  const AddressCopyAddressHandler = () => {
    const text = walletAddress;

    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  useEffect(() => {
    Dashboard_API();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row">
            <div className="col-lg-12">
              <div className="nftHeading">
                <h2 className="fs-18">DASHBOARD</h2>
              </div>
              {/* {dash?.CurrentPackage > 0 && (
                <div className="text-center">
                  <Timer endTime={dash?.ActivationDate} />
                </div>
              )} */}
            </div>

            <div className="col-lg-9">
              <div className="row mx-0 gy-2">
                <div className="col-lg-6">
                  <div className="card mycard crdgjaiq">
                    <div
                      className="card-body popular_nfts "
                      style={{ height: "153px" }}>
                      <div className="d-sm-flex align-items-center justify-content-between  nftteam2nd ">
                        <div className="fs-16 fw-bold text-white text-uppercase">
                          My Package
                          <br />
                          <div className="fs-14 fw-bold text-white text-uppercase my-2">
                            Current Package-:{dash.CurrentPackage}
                          </div>
                          <div className="fs-14 fw-bold text-white text-uppercase">
                            gross Package-:{dash.TotalInvest}
                          </div>
                        </div>
                        <div className="TotalEarnChatMain" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card mycard2 crdgjaiq">
                    <div className="card-body ">
                      <div className="card-heading text-white fs-6 fw-bold pb-3 text-uppercase">
                        Team Size
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                            Left{" "}
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/download.png"
                                alt=""
                              />
                            </span>
                          </div>
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                            {" "}
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon2.png"
                                alt=""
                              />
                            </span>
                            Right
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Total : {dash.TotalLeftTeam}{" "}
                          </div>
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Total : {dash.TotalRightTeam}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Active : {dash.TotalLeftActiveTeam}
                          </div>
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Active : {dash.TotalRightActiveTeam}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="card mycard2 crdgjaiq">
                    <div className="card-body ">
                      <div className="card-heading text-white fs-6 fw-bold pb-3 text-uppercase">
                        Team Business
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                            Left{" "}
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/download.png"
                                alt=""
                              />
                            </span>
                          </div>
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                            {" "}
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon2.png"
                                alt=""
                              />
                            </span>
                            Right
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Total : {dash.totalLeftTeamBusiness}
                          </div>
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Total : {dash.totalRightTeamBusiness}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Today : {dash.todayLeftTeamBusiness}
                          </div>
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Today : {dash.todayRightTeamBusiness}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card mycard2 crdgjaiq">
                    <div className="card-body ">
                      <div className="card-heading text-white fs-6 fw-bold pb-3 text-uppercase">
                        My Referral
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                            Left{" "}
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/download.png"
                                alt=""
                              />
                            </span>
                          </div>
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2">
                            {" "}
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon2.png"
                                alt=""
                              />
                            </span>
                            Right
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Total : {dash.TotalLeftReferral}
                          </div>
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Total : {dash.TotalRightReferral}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Active : {dash.TotalLeftActiveReferral}
                          </div>
                          <div className="fs-14 text-white fw-normal imagetext d-flex align-items-center gap-2 textimagesM">
                            <span className="d-flex align-items-center justify-content-center">
                              <img
                                src="https://wyzzkwyscale.com/assets/images/newimage/icon1.png"
                                alt=""
                              />
                            </span>
                            Active : {dash.TotalRightActiveReferral}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt30hsff dmt-80 g-3">
                <>
                   <div className="col-lg-3">
                    <div className="SellersItem">
                      <div className="SellersText">
                        <h2 className="timetext">
                          <span>
                            <img
                              className="w-100 h-100"
                              src={tokenbuy2}
                              alt=""
                            />
                          </span>
                          Buy Rate
                        </h2>
                        <p className="text-end">$ {dash?.BuyRate}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="SellersItem">
                      <div className="SellersText">
                        <h2 className="timetext">
                          <span>
                            <img
                              className="w-100 h-100"
                              src={tokenbuy}
                              alt=""
                            />
                          </span>
                          Sell Rate{" "}
                        </h2>
                        <p className="text-end">$ {dash?.SellRate}</p>
                      </div>
                    </div>
                  </div> 
                   <div className="col-lg-3">
                    <div className="SellersItem">
                      <div className="SellersText">
                        <h2 className="timetext">
                          <span>
                            <img className="w-100 h-100" src={buysale} alt="" />
                          </span>
                          Total Token Sold
                        </h2>
                        <p className="text-end">{dash.Total_Sold_Token}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="SellersItem">
                      <div className="SellersText">
                        <h2 className="timetext">
                          <span>
                            <img
                              className="w-100 h-100"
                              src={totaltoken}
                              alt=""
                            />
                          </span>
                          Total Token
                        </h2>
                        <p className="text-end">{dash.Total_Token}</p>
                      </div>
                    </div>
                  </div> 
                </>

                <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={Direct_Income}
                            alt=""
                          />
                        </span>
                        Direct Income
                      </h2>
                      <p className="text-end">$ {dash.directincome}/ {dash.directincome_T} </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={matrixincome}
                            alt=""
                          />
                        </span>{" "}
                        ROI Income{" "}
                      </h2>
                      <p className="text-end">$ {dash.ROI_INCOME} / {dash.ROI_INCOME_T} </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100 p-2"
                            src={MatrixCTOIncome}
                            alt=""
                          />
                        </span>
                        ROI Level Income
                      </h2>
                      <p className="text-end">$ {dash.ROI_level_income} / {dash.ROI_level_income_T} </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={withincome}
                            alt=""
                          />
                        </span>
                        Withdraw Share Income
                      </h2>
                      <p className="text-end">
                        $ {dash.withdraw_direct_income} / {dash.withdraw_direct_income_T}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={Withdrawal_Amount}
                            alt=""
                          />
                        </span>
                        Salary Income
                      </h2>
                      <p className="text-end">$ {dash.salaryincome} / {dash.salaryincome_T}</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={NetWithdrawalBalance}
                            alt=""
                          />
                        </span>
                        Long Term Reward
                      </h2>
                      <p className="text-end">
                        $ {dash.LongTerm_Reward_income} / {dash.LongTerm_Reward_income_T}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={tokenbuy}
                            alt=""
                          />
                        </span>
                        Short Term Reward
                      </h2>
                      <p className="text-end">
                        $ {dash.ShortTerm_Reward_income} / {dash.ShortTerm_Reward_income_T}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={buysale}
                            alt=""
                          />
                        </span>
                        Total Withdrawal
                      </h2>
                      <p className="text-end">$ {dash.WithDrawal_Amount}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={ra}
                            alt=""
                          />
                        </span>
                        Rapid Fund Income
                      </h2>
                      <p className="text-end">$ {dash.RapidFund}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={tokenbuy2}
                            alt=""
                          />
                        </span>
                        Total Rapid Fund
                      </h2>
                      <p className="text-end">$ {dash.Total_RapidFund}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={total}
                            alt=""
                          />
                        </span>
                        Used Rapid Fund
                      </h2>
                      <p className="text-end">$ {dash.Used_RapidFund}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={to}
                            alt=""
                          />
                        </span>
                        Balance Rapid Fund
                      </h2>
                      <p className="text-end">$ {dash.Balance_Fund}</p>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-lg-3">
                  <div className="SellersItem">
                    <div className="SellersText">
                      <h2 className="timetext">
                        <span>
                          <img
                            className="w-100 h-100"
                            src={NetWithdrawalBalance}
                            alt=""
                          />
                        </span>
                        Total Withdrawal
                      </h2>
                      <p className="text-end">$ 0</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-3 neggnarg">
              <div className="row gy-2">
                <div className="col-lg-4" />
                <div className="col-lg-4">
                  <div className="Img_profile">
                    <center>
                      <img src={dummy} className="img_p" />
                    </center>
                  </div>
                </div>
                <div className="zackItem">
                  <div className="profileSection">
                    <h2>User Id:</h2>
                    <p className="mb-0">{dash.userid}</p>
                    <br></br>
                    <div className="SellersItem1 shadow-none border-0">
                      <div className="SellersText">
                        <h2>Wallet Address:</h2>
                        <p className="text-center ksjhajq" id="walletAddress">
                          {`${dash?.btcaddress?.substring(
                            0,
                            4
                          )}...${dash?.btcaddress?.substring(
                            dash?.btcaddress?.length - 4
                          )}`}
                        </p>
                      </div>
                    </div>
                    <br></br>
                    <div className="SellersItem1 shadow-none border-0">
                      <div className="SellersText">
                        <h2>Your IP Address:</h2>
                        <p className="mb-0">{ipAddress}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="zackItem mt-3">
                  <div className="profileSection py-3 he">
                    <h2 className="timetextimg d-flex align-items-center justify-content-between binerincome">
                      <span>
                        <img
                          className="w-100 h-100"
                          src={binaryincome}
                          alt=""
                        />
                      </span>
                      Binary Income
                    </h2>
                    <p className="mb-0 text-end">$ {dash.Binary_income}</p>
                  </div>
                </div>
                <div className="zackItem mt-3 ">
                  <div className="profileSection py-2 he">
                    <h2 className="timetextimg d-flex align-items-center justify-content-between binerincome">
                      <span>
                        <img
                          className="w-100 h-100"
                          src={Total_Income}
                          alt=""
                        />
                      </span>
                      Total Income
                    </h2>
                    <p className="mb-0 text-end">{dash.Total_Income}</p>
                  </div>
                </div>

                <div className="zackItem ">
                  <div className="profileSection py-2 he">
                    <h2 className="timetextimg d-flex align-items-center justify-content-between binerincome">
                      <span>
                        <img
                          className="w-100 h-100"
                          src={totaltoken}
                          alt=""
                        />
                      </span>
                      Net Balance
                    </h2>
                    <p className="mb-0 text-end">$ {dash.Net_Balance}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt30hsffhxn">
              <div className="col-lg-4 pt-1">
                <div className="popular_nfts jsbdjxsd mb22">
                  {/* <img src={img1} class="minHt frstemghxn" /> */}
                  <div className="nftTimeItem">
                    <h1 id="leftTime" />
                    <p>Welcome to the world of 36t</p>
                    <div className="TotalEarnChatMain mt-3">
                      <center>
                      <div className="priceChart1">
                        <span className="priceChart_text">
                          $ {dash.TotalInvest}
                        </span>
                        {/* <div className="slice">
                          <div className="bar" />
                          <div className="fill" />
                        </div> */}
                      </div>
                      </center>
                    </div>
                    <div className="d-sm-flex align-sm-items-center gap-3">
                      <button
                        type="button"
                        className="btn button btnhome w-50 leffttf"
                        onClick={LeftCopyAddressHandler}>
                        Copy Left Referral Link
                      </button>
                      <button
                        type="button"
                        className="btn button btnhome w-50"
                        onClick={RightCopyAddressHandler}>
                        Copy Right Referral Link
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 pt-1 resSeb">
                <div className="popular_nfts jsbdjxsd">
                  {/* <img src={img1} class="img-fluid minHt heightdgt" /> */}
                  <div className="nftTimeItem reassa">
                    <div className="d-sm-flex align-items-center justify-content-between gy-3">
                      <div className="">
                        <h1 id="leftTime2" />
                        <p className="bgcolor mb-2">
                          Binary 3x Capping <br></br>($ {dash.MaxIncome})
                        </p>
                        <div className="TotalEarnChatMain mt-3">
                          <center>
                          <div className="priceChart1">
                            <span className="priceChart_text">
                              $ {dash.EarnAmount}
                            </span>
                            <div className="slice">
                              <div className="bar" />
                              <div className="fill" />
                            </div>
                          </div>
                          </center>
                        </div>
                        <h2>Status : {dash.EarnPer} %</h2>
                      </div>
                      <div className="">
                        {/* <h1 id="leftTime2" /> */}
                        <p className="bgcolor mb-2">
                          Token 2x Capping <br></br>($ {dash.MaxIncomeroi})
                        </p>
                        <div className="TotalEarnChatMain mt-3">
                          <center>
                          <div className="priceChart1">
                            <span className="priceChart_text">
                              $ {dash.EarnAmountroi}
                            </span>
                            <div className="slice">
                              <div className="bar" />
                              <div className="fill" />
                            </div>
                          </div>
                          </center>
                        </div>
                        <h2>Status : {dash.EarnPerroi} %</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                  <div className="card myteamcard">
                    <div className="card-header py-3 bgmain">
                      INCOME SUMMARY
                    </div>
                    <div className="row mx-0">
                      <div className="col-12">
                        <ul className="p-0 m-0 listcricle">
                          <li className="d-flex my-3 p-1">
                            <div className="circle-small">
                              <div className="text">$ {dash.directincome} </div>
                              {/* <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg> */}
                            </div>
                            <div className="row align-items-center">
                              <div className="col-lg-12">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Direct Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">$ {dash.ROI_INCOME} </div>
                              {/* <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress one"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg> */}
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-lg-12">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    ROI Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">
                                $ {dash.ROI_level_income}{" "}
                              </div>
                              {/* <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg> */}
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-lg-12">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    ROI Level Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">
                                $ {dash.withdraw_direct_income}
                              </div>
                              {/* <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg> */}
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-lg-12">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Withdraw Share Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">$ {dash.salaryincome}</div>
                              {/* <svg>
                                <circle className="bg" cx={40} cy={40} r={37} />
                                <circle
                                  className="progress two"
                                  cx={40}
                                  cy={40}
                                  r={37}
                                />
                              </svg> */}
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-lg-12">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Salary Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li>
                          {/* <li className="d-flex mb-3 pb-1">
                            <div className="circle-small">
                              <div className="text">$ {dash.RapidFund}</div>
                              
                            </div>
                            <div className="row w-100 align-items-center">
                              <div className="col-lg-12">
                                <div className="me-2">
                                  <h6 className="mb-2 fontss text-uppercase">
                                    Rapid Fund Income
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="resize-triggers">
                              <div className="expand-trigger">
                                <div />
                              </div>
                              <div className="contract-trigger" />
                            </div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
               
                <div class=" nftTimeItem rounded-3 leftcard">
                <div class="card-header p-2 bg-theme1 w-100">
                    <h4 class="card-title mb-0 w-100">BUY AND SALE DETAILS</h4>
                </div>
                <div class="card-body table_main">
                    <table role="table">
                      <thead class="border_bottom">
                          <tr role="row">
                            <th colspan="1" role="columnheader">S.No</th>
                            <th colspan="1" role="columnheader">Buy Rate</th>
                            <th colspan="1" role="columnheader">Sell Rate</th>
                            <th colspan="1" role="columnheader">USD</th>
                            <th colspan="1" role="columnheader">Token.No</th>
                            <th colspan="1" role="columnheader">Remark</th>
                            <th colspan="1" role="columnheader">Txn</th>
                            <th colspan="1" role="columnheader">Date</th>
                          </tr>
                      </thead>
                      <tbody role="rowgroup">
                          <tr class="table_tr" role="row">
                            <td role="cell">1</td>
                            <td role="cell">0.01</td>
                            <td role="cell">0</td>
                            <td role="cell">0</td>
                            <td role="cell">0</td>
                            <td role="cell"><button class="btn text-white px-3 py-1 sell shadow-none">Sell</button></td>
                            <td role="cell"></td>
                            <td role="cell">14/02/2024, 01:37 PM</td>
                          </tr>
                      </tbody>
                    </table>
                </div>
                </div>
             
            </div>
            <div className="container">
              <div className="row mt30hsff2 mb50 g-3 popular_nfts">
                <div className="col-md-12">
              
              </div>
                {/* <div className="col-lg-6">
                  <div className="card myteamcard">
                    <div className="card-header py-3 bgmain text-uppercase">
                      MY ROI Level Income
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 1</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">0</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ 0
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 2</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">0</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ 0
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 3</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">0</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ 0
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 4</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">0</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ 0
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 5</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">0</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ 0
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 6</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">0</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ 0
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 7</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">0</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ 0
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 8</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">0</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ 0
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 9</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">0</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ 0
                        </button>
                      </div>
                    </div>
                    <div className="cardteam bg-transparent py-2 row mx-0 align-items-center">
                      <div className="col-4">
                        <h6>Level 10</h6>
                      </div>
                      <div className="col-4">
                        <h6 className="fw-bold">0</h6>
                      </div>
                      <div className="col-4">
                        <button className="btn btn1 px-4 py-2 text-white fw-bold">
                          $ 0
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
                
              </div>
            </div>

            {/* <DbodBuysellReport /> */}
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default DashboardMain;
