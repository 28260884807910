import React, { useEffect, useState } from "react";
import "./Register.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { loadWeb3 } from "../../Utility/contract";
import { API } from "../../Utility/API";
import axios from "axios";
import toast from "react-hot-toast";
import updateAuth from "../../Redux/AuthSlice";

const Register = () => {
  let navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [sponser, setSponser] = useState(searchParams.get("userid"));
  const [selectPosition, setselectPosition] = useState(
    searchParams.get("position") === "Left"
      ? 1
      : searchParams.get("position") === "Right"
      ? 2
      : ""
  );

  const [registor_Model, setregistor_Model] = useState(false);
  const [connected, setconnected] = useState(true);
  const [sid, setSid] = useState(sponser);
  const [LoginId, setLoginId] = useState();

  // console.log("sid", sid);
  const dispatch = useDispatch();

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      let response = await axios.get("https://geolocation-db.com/json/");
      // console.log("response Geolocation", response.data.IPv4);
      // setIP(response.data.IPv4);
      let ipaddress = response.data.IPv4;
      if (acc === "No Wallet" || acc === undefined) {
        toast.error("Please Connect Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setLoginId(acc);
        if (acc !== "") {
          let res = await API.post(`/login`, {
            password: acc,
            ipaddress: ipaddress,
          });
          // console.log("UserloginNewAuto", res.data.token);
          if (res.data.data.result === "Successfull") {
            toast.success(`Login Successful`);
            // console.log("loginmask", res.data.token);
            navigate("/DashBoard");
            dispatch(
              updateAuth({
                clubAuth: true,
                userId: res.data.data.uid_output,
                jwtToken: res.data.token,
                ipAddress: ipaddress,
              })
            );
          } else {
            toast.error(res.data.data.result);
          }
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const registerUser = async () => {
    let acc = await loadWeb3();
    let response = await axios.get("https://geolocation-db.com/json/");
    // console.log("response Geolocation", response.data.IPv4);
    // setIP(response.data.IPv4);

    let ipaddress = response.data.IPv4;
    if (!acc) {
      toast.error("Wrong Network Please Connect BSc Network");
      setconnected("Wallet is locked");
    } else if (acc === "No Wallet" || acc === undefined) {
      toast.error("Please Connect Wallet");
      setconnected("Wallet is locked");
    } else if (acc == "Wrong Network") {
      toast.error("Wrong Network Please Connect BSc Network");
      setconnected("Wrong Network");
    } else {
      try {
        if (selectPosition === "" || selectPosition === undefined) {
          toast.error("Select Position!!");
        }
        if (sid === "" || sid === undefined) {
          toast.error("Enter Upline Id");
        }
        let res = await API.post("registration", {
          sid: sid,
          accountnumber: acc,
          position: selectPosition,
          addresslist: "",
          amountList: "",
          paymentType: "",
          status: "1",
        });
        // console.log("registration", res.data);
        if (res.data.data.result === "Successfull") {
          toast.success("Register Successfull");
          let res = await API.post(`/login`, {
            password: acc,
            ipaddress: ipaddress,
          });
          // console.log("reglogin2-->", res);
          // let UserID = res.data.data;
          // let jwtToken = res.data.token;
          // console.log("jwttoken ru", jwtToken);
          if (res.data.data.result === "Successfull") {
            toast.success(`Login Successful`);
            dispatch(
              updateAuth({
                clubAuth: true,
                userId: res.data.data.uid_output,
                jwtToken: res.data.token,
                ipAddress: ipaddress,
              })
            );
            navigate("/DashBoard");
          } else {
            toast.error(`Invalid User id or Address`);
          }
        } else {
          toast.error(`${res.data.data.result}`);
        }
      } catch (e) {
        console.log("Error while regiater", e);
      }
    }
  };

  useEffect(() => {
    walletConnected();
    let intervalId = setInterval(walletConnected, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      <div className="bgimage">
        <div className="container-fluid">
          <div className="container vh-100 ">
            <div className="row align-items-center justify-content-center h-100">
              <div className="col-sm-4">
                <div
                  className="card"
                  style={{ border: "2px solid rgb(81 118 5)" }}
                >
                  <div
                    className="card-body"
                    style={{ backgroundColor: "rgba(0, 97, 0, 0.28)" }}
                  >
                    <div className="row mx-0 align-items-center justify-content-center gy-3">
                      <div className="col-12">
                        <div className="logoimage mx-auto">
                          <a href="/">
                          <img
                            className="w-100 h-100"
                            src="./assets/images/logo.png"
                            alt=""
                          />
                          </a>
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <div className="loginheading py-5">
                          Access Your Personal Account
                        </div>
                        <div
                          className="subtext"
                          style={{
                            color:
                              connected === "Wallet is Connected"
                                ? "green"
                                : "red",
                          }}
                        >
                          {connected}
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <button
                          className="btn btnlogin px-4"
                          onClick={() => setregistor_Model(true)}
                        >
                          Register
                        </button>
                      </div>
                      <div className="col-12">
                        <div className="remembertext">
                          Remember to authorize with the correct address.View an
                          Account
                        </div>
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          className="control-form"
                          placeholder="Please Enter ID or Address"
                          value={LoginId}
                          readOnly
                        />
                      </div>
                      <div className="col-12 text-center">
                        <button className="btn btnlogin px-4">
                          Connect to Wallet
                        </button>
                      </div>
                      <div className="col-12">
                        <div className="remembertext">
                          Enter the account id or BNB Address
                        </div>
                      </div>
                      <div
                        id="myModal"
                        className="modal"
                        style={{ display: registor_Model ? "block" : "none" }}
                      >
                        <div className="modal-content-register boxset">
                          <h4>Enter Upline Id</h4>
                          <br />
                          <p>
                            {" "}
                            <input
                              id="txtsid"
                              className="control-form"
                              value={sid}
                              maxLength={8}
                              type="text"
                              min={0}
                              placeholder="Enter Upline Id"
                              onChange={(e) => {
                                const inputText = e.target.value
                                  .replace(/[^0-9]/gi, "")
                                  .substring(0, 8);
                                const sanitizedInput =
                                  DOMPurify.sanitize(inputText);
                                setSid(sanitizedInput);
                              }}
                            />
                          </p>
                          <select
                            className="form-control"
                            id="__BVID__17"
                            value={selectPosition}
                            onChange={(e) => setselectPosition(e.target.value)}
                          >
                            <option>Please Select Position</option>
                            <option value={1}>Left</option>
                            <option value={2}>Right</option>
                          </select>
                          <br />
                          <div className="rerlbtn">
                            <button
                              className=" btn_Model"
                              onClick={() => {
                                registerUser();
                              }}
                            >
                              Register
                            </button>
                            <button
                              className="mx-2 btn_Model"
                              onClick={() => setregistor_Model(false)}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="remembertext2">
                          If you have already registered then
                          <Link
                            to="/Login"
                            style={{ color: "rgb(234, 239, 9)" }}
                          >
                            {" "}
                            Login
                          </Link>
                        </div>
                        <div className="remembertext2">
                          Copyright © 2024. All Rights Reserved.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
